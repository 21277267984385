import * as React from "react";
import { StyledEngineProvider } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import Layout from '../components/Layout';

const pictures = [
  {
    img: '/pictures/martha-shea-snow-201911.jpg',
    title: 'Martha, Shea, Minnesota snow'
  },
  {
    img: '/pictures/fountain-202105.jpg',
    title: 'Mike, Shea, fountain'
  },
  {
    img: '/pictures/xmas2020-bw.jpg',
    title: 'Shea in the Maryland cold'
  },
  {
    img: '/pictures/pickle-it-202007.jpg',
    title: 'Fermenting pickles'
  },
  {
    img: '/pictures/c2020f3-2020-july-9.jpg',
    title: 'Comet C/2020 F3 (NEOWISE) in the Maryland morning'
  },
  {
    img: '/pictures/double-rainbow-202006.jpg',
    title: 'Double bow'
  },
  {
    img: '/pictures/garden-chiles-2021.jpg',
    title: 'Part of the 2021 chile crop'
  },
  {
    img: '/pictures/rocky-201910.jpg',
    title: 'Rocky'
  },
  {
    img: '/pictures/first-dentist.jpg',
    title: 'First dentist appointment'
  },
  {
    img: '/pictures/martha-shea-fountain.jpg',
    title: ''
  },
  {
    img: '/pictures/shea-1.jpg',
    title: ''
  },
  {
    img: '/pictures/shea-2.jpg',
    title: ''
  },
  {
    img: '/pictures/shea-3.jpg',
    title: ''
  },
  {
    img: '/pictures/shea-4.jpg',
    title: 'Picking pumpkins'
  },
  {
    img: '/pictures/martha-shea-zoo.jpg',
    title: 'At the Maryland Zoo in Baltimore'
  },
  {
    img: '/pictures/shea-5.jpg',
    title: 'Picking apples'
  },
];

export default function Pictures() {
  return (
    <StyledEngineProvider injectFirst>
      <Layout subtitle="Pictures">
        <Box p={1}>
          <ImageList sx={{ width: '100%', height: '100%' }}>
            {pictures.map((item) => (
              <ImageListItem key={item.img} sx={{ background: '#444' }}>
                <img
                  src={item.img}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={item.title}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Layout>
    </StyledEngineProvider>
  );
}